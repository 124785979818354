@import '../../index.scss';

.languages-dropdown {
  border: 2px solid $primaryColor !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  min-width: 80px !important;
  max-width: 80px !important;
  background: transparent;
  margin:0px 8px;
  height: 31px !important;
  img {
    margin-right: 2px;
  }
  .p-dropdown-trigger {
    display: none;
  }
  
  &:hover{
    background-color: $secondaryColor;
    border: 2px solid $primaryColor !important;
    color: $primaryColor !important;
  }
  &:focus{
    background-color: $secondaryColor;
    border: 2px solid $primaryColor !important;
    color: $secondaryColor !important;
    box-shadow: none !important;
  }
 
}

.country-item {
  display: flex !important;
}

.country-item-value {
  display: flex !important;
  align-items: center;
}

.lang-span {
  font-weight: 700;
  margin-left: 8px;
}
