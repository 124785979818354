.checkbox {
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    padding-right: 5px;
    font-size: large;
    position: relative;
    line-height: 40px;
    margin-left: 4px;
    min-width: 100px;
    .checkbox-text{
      cursor: pointer;
      font-size: medium;
    }
  }
  .chechbox-right {
    display: flex;
    align-items: center;
  }
  .checkbox-input {
    cursor: pointer;
    margin: 2px 0;
    position: absolute;
    bottom: 3px;
    right: 12px;
    .none {
      display: none;
    }
  }
  
  .checkbox-input.active {
    .none_ {
      display: none;
    }
    .none {
      display: block;
      fill: #3e86ff !important;
    }
  }
  