@import '../../../index.scss';

.Sidebar {
  background: $secondaryColor;
  // border: 1px solid $primaryColor;
  // border-radius: 6px 6px 0 0;
  // max-height: 600px; //445px;
  // width: 248px;
  // left: 16px;
  // min-width: 250px;
  position: absolute;
  top: 55px;
  left: 0;
  width: 335px;
  pointer-events: all;
  height: calc(100vh - calc(44px + 55px) );
  overflow: auto;
  color: #000000;

  .line {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    margin-left: 10px;

    .p-treenode-label {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;

      b {
        font-weight: 400 !important;
      }
    }
  }

  .selected-line {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    background: #485f76 !important;
    border-radius: 5px;
    margin: 0px 10px;

    .p-treenode-label {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;

      b {
        font-weight: 400 !important;
      }
    }
  }

}

.p-treenode-icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-treenode-leaf {
  .p-tree-toggler {
    width: auto !important;
    display: none;
  }
}

.p-treenode-content {
  .p-checkbox {
    order: 3;
    margin-left: auto;
  }

  .p-treenode-label {
    text-align: start;
  }
}

.p-tree-container{
  padding-right: 0.5rem;
  .p-treenode{
    padding-right: 0 !important;
    .p-treenode-leaf{
      padding-right: 0 !important;
    }
  }
}

.label {
  padding: 5px;
  text-align: start;

  b {
    font-weight: 400;
    color: $textColor;
  }
}

.boldClass {
  b {
    font-size: 16px;
    font-weight: 700;
    color: $primaryColor;
  }
}

.boldGroup {
  b {
    font-weight: 700;
  }
}

.p-tree-toggler {
  margin: 0 !important;
}

.disabled {
  pointer-events: none !important;
}

.raster-icon {
  background-image: url('../../../../public/icons/lidar.svg')
}

.collapse-button {
display: flex;
justify-content: flex-start;
  .p-button{
    border: none !important;

    span{
    color: $primaryColor;

    font-size: 1.5rem;
    }
    img{
      width: 2rem;
    }
  } 
}