.container{
    position:absolute;
    top: 80px;
    left: 80px;
    background:#17212f;
    padding: 1em;
    z-index: 100;
    .title{
        font-size: 1.5em;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i:hover{
            cursor: pointer;
        }
    }
    .body {
        height: 530px !important;
    
    }
    
    .info-container {
        display: flex;
        justify-content: center;
        
    }
    
    .info-column div {
        border: 1px solid #cccccc;
        margin-left: -1px;
        margin-top: -1px;
        color: white;
        padding: 8px;
        min-width: 50px;
      }
    
    
    .info-selected div {
        border-color: #cc0000;
    }

}
.pi .pi-times{
    color: white;
}