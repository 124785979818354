@import "../../../../../index.scss";
$paddingVal: 10px;
// DEFAULT INPUT START
$defaultInputDigitizationInputBackground: $lightbackgroundColor;
$defaultInputDigitizationInputColor: #d8dbe3;
$defaultInputDigitizationInputName: #d8dbe3;
.take-dialog {
  .body {
    border-top: 1px solid $primaryColor;
    background-color:  $lightbackgroundColor;
    color: #d8dbe3;
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    .label-group {
      display: flex;
      align-items: center;
    }
   
.digitization-input {
    padding-left: 8px;
    display: flex;
    align-items: center;
    min-width: 180px !important;
    max-width: 230px !important;
    height: 32px;
    background-color: $defaultInputDigitizationInputBackground;
    border-radius: 1px;
    border: 1px solid #838387;
    color: $defaultInputDigitizationInputColor;
    font-size: 14px;
    font-weight: bold;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.9);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.9);
    appearance: textfield;
    &::placeholder {
      font-style: italic;
    }
  }
  .digitization-input[type="number"]::-webkit-inner-spin-button,
  .digitization-input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .digitization-input:disabled {
    border: none;
  }
  .digitization-input-name {
    width: 110px;
    height: 32px;
    color: $defaultInputDigitizationInputName;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
  
  .digitization-input-name + span {
    margin: 0 4px 12px 0;
  }
  
  .digitization-input-error {
    font-size: 12px;
    color: orangered;
    margin-left: 156px;
    margin-top: 10px;
  }
  
  }
  .footer {
    background-color: $lightbackgroundColor;
    color: #d8dbe3;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 8px 0px;

        .p-button {
            width: auto !important;
            color: $primaryColor;
            padding: 5px 10px !important;
            margin: 2px !important;
        }

    }

    // button {
    //   width: 88px;
    //   height: 32px;
    //   font-size: 14px;
    //   font-weight: bold;
    //   border-radius: 4px;
    //   background-image: linear-gradient(#3e86ff, #2a5cae, #2a5cae);
    //   color: #ffffff;
    //   outline: none;
    //   border: none;
    //   &:disabled {
    //     color: #c1c1c1;
    //     background-image: #2856b5;
    //   }
    // }
  }
}
