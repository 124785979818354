@import "../../index.scss";

.login {
  display: flex;
  align-items: center;
  height: 100vh;
  color: #495057;
  background-color: $secondaryColor;
  width: 100%;
  overflow: scroll;
  margin: 0 auto;
  padding: 90px;
  column-gap: 50px;
  justify-content: center;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50% !important;
    max-width: 500px;
    // flex-grow: 1 !important;
    .line {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 25px;

      .label {
        width: 120px;
        font-weight: 500;
        font-size: 18px;
      }
      .p-inputtext,
      .p-password {
        flex-grow: 1;
      }
    }
    .login-button{ 
      width: 100%;
      .p-button{
        width: 100% !important;
        color: $textColor;
        padding: 8px !important;
        margin-top: 20px !important;
        height: 40px !important;
        background-color: $secondaryColor !important;
        span {
            font-size: 20px;
        }
      }
    }

    .confirm {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }  

  .right{
    width: 30%;
    // flex-grow: 1 !important;
    display: flex;
    justify-content: center;

    img{
      width: 100%;
    }
  }

}

.text-color {
  color: $textColor;
}

@media (max-width: 1000px) {
  .login {
    padding: 50px !important;
    flex-direction: column-reverse;
    padding: 90px;    
    .left {
      margin-top: 50px;
      order: 1;
      width: 80% !important;
    }

    .right {
      order: 3;
    }
  }
}

@media (max-width: 500px) {
  .login {
    width: 100% !important ;
    padding: 20px !important;

    .left {
      width: 100% !important;
      .login-button {
        width: 100% !important;
        .p-button {
          width: 100% !important;
        }
      }
      .confirm {
        width: 100%;
      }

      .line {
        width: 100%;
        .p-inputtext,
        .p-password {
          width: auto !important;
          flex-grow: 1;
        }
      }
    }    

    .right {
      width: 100% !important;
      max-width: 200px;
    }
  }
}

@media (max-width: 325px) {
  .login {
    .left {
      .line {
        .label {
          font-size: 13px;
          min-width: auto !important;
        }
      }
    }
  }
}
