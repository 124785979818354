@import '../../../index.scss';

// .Navbar {
//     display: flex;
//     width: 100%;
//     justify-content: space-between;
//     position: relative;

//     .left-content {
//         display: flex;
//         align-content: center;

//         .sidebar-button {
//             display: flex;
//             align-items: flex-start;

//             .text {
//                 font-size: 18px;
//                 padding: 0px 8px 0px 4px;
//                 color: $primaryColor;
//                 // width: 237px;
//                 text-align: start;
//                 margin-right: 25px;
//             }
//         }

//         .vertical-line {
//             width: 1px;
//             height: 30px;
//             background: #80808078;
//             margin: 0px 8px;
//         }

//         .pi-question {
//             color: $textColor;
//         }

//         .pano-type-dropdown {
//             height: 30px;
//             margin-right: 1em;
//             width: 131px;

//             .p-dropdown-label {
//                 padding-top: 4px;
//             }
//         }
//     }

//     .right-content {
//         display: flex;
//         justify-content: flex-end;
//         align-items: center;

//         .text {
//             margin: 0px 8px;
//             color: $textColor;
//         }

//         .search-input {
//             border-color: $textColor;
//             height: 30px;

//             &:hover {
//                 border-color: $primaryColor;
//             }
//         }

//         .logout-button {
//             width: auto;
//             color: $textColor;
//             padding: 0.3em !important;

//             img {
//                 margin-left: 5px;
//             }

//             &:hover {
//                 color: $textColor;
//             }
//         }
//     }
// }
.tooltip-setting{
    display: flex;
    justify-content: space-around;
}
.show {
    display: block;
}

.hide {
    display: none;
}

.Navbar{
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    min-height: 35px;
    .content{
        width: 100%;
        display: flex;
        .group-1{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: 180px;
            .sidebar-box{
                display:flex;
                align-items: center;
                justify-content: center;
                // flex-wrap: wrap;
                min-width:63px ;
            }
        }

        .group-1bucuk {
            display: flex;
            .zemin-box{
                justify-content: center;
                padding: 0 5px;

                .pano-type-dropdown{
                    height: 30px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    
        .group-2{
            display: flex;
            // column-gap : 10px;
    
            .digi-button-box{
                display: flex;
                flex-wrap: wrap;
                min-width: 166px;
                justify-content: center;
            }
            
            .selection-box{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                justify-content: center;
                min-width: 93px;
                
            }
        }
    
        .group-3{
            display: flex;
            // column-gap : 10px;
    
            .group-3-1{
                display: flex;
    
                .draw-box{
                    display: flex;
                    flex-wrap: wrap;
                    min-width: 166px;                    
                    justify-content: center;
                }
            }
    
            .group-3-2{
                display: flex;
                .export-box{
                    display: flex;
                    flex-wrap: wrap;
                    min-width: 83px;                   
                    justify-content: center;
                }
                .way-profile-box{
                    display: flex;
                    flex-wrap: wrap;               
                    justify-content: center;
                }
                .access-box{
                    flex-wrap: wrap;
                    display: flex;
                    min-width: 83px;                
                    justify-content: center;
                }
            }
        }
    
        .group-4{
            margin-left: auto;
            display: flex;    
            .choose-box{
                display: flex; 
                justify-content: center;
            }
            .logout-button {
                width: auto;
                color: $textColor;
                padding: 0.3em !important;
                z-index: 11;
                img {
                    margin-left: 5px;
                }

                &:hover {
                    color: $textColor;
                }
            }
        }
    }
    
}

.vertical-line-box{
    min-width: 3px;
    height: 30px;
    background: #80808078;
    margin: 0 5px;   
}
.vertical-line-group{
    min-width: 3px;
    height: 30px;
    background: #80808078;
    margin: 0 5px;
}

.text {
    margin: 0px 8px;
    color: $textColor;
    max-width: 180px;
    line-break: anywhere;
}

.search-input {
    border-color: $textColor;
    height: 30px;

    &:hover {
        border-color: $primaryColor;
    }
}
.pi-question {
    color: $textColor;
}

.menu{
    position: absolute;
    right: 1em;
    top: 40px;
    z-index: 10;
    .p-menuitem{
        border-bottom: 1px solid #304562 !important;
    }
}