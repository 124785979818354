@import '../../index.scss';
.kkn-info {
  position: fixed;
  left: 0;
  bottom: 0px; //255px;
  padding: 10px;
  background-color:rgba(
    $color: #fff,
    $alpha: 0.8
  );
  border-top-right-radius: 8px;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 99999999999999999999999;
  &.right-bottom {
    left: auto;
    right: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 8px;
  }
  &.left-top {
    height: fit-content;
    top: 0;
    left: 0;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0;
  }

  .left {
    display: flex;
    width: 50%;

    .label {
        margin: 0px 10px;
    }
  }

}
.right-top {
  top: 24px;
  right: 36px;
  position: absolute;
}
.print-dialog {
  
    .p-dialog-header {
        border-bottom: 0 none;
        // background: $primaryColor !important;
        color: white !important;
        padding: -1.5rem !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: white;
        }
    }
    .p-dialog-content {
        padding-top: 25px;
        width: 360px;

        .p-button {
            color: white !important;
            width: 105px !important;
        }

        .p-progressbar {
            display: none !important;
        }
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button,
    .p-fileupload-choose.p-button-danger {
        display: none;
    }

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {
        display: none;
    }
    .custom-choose-btn {
        border-radius: 6px !important;
        background: $primaryColor;
        .pi-plus {
            display: none;
        }
    }
    .data-type-header {
        margin: 0 auto;
        font-size: medium;
        font-weight: 400;
    }
    
    .pair{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .item {
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;
        
            .item-header {
              font-size: large;
              font-weight: bold;
            }
        
            label {
              user-select: none;
              margin: 5px 0;
              display: flex;
              justify-content: space-between;
              line-height: 30px;
              cursor: pointer;
              font-size: large;
            }
            textarea {
              resize: vertical;
              width: 100%;
            }
          }

          .button {
            width: 88px;
            height: 32px;
            font-size: 14px;
            font-weight: bold;
            border-radius: 4px;
            border: none;
            color: #d8dbe3;
            cursor: pointer;
          }
          .footer {
            display: flex;
            justify-content: space-between;
        
            label {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
            }
            .yellow {
              background-image: linear-gradient(#ff9606, #b16907, #b16907);
            }
        
            .blue {
              background-image: linear-gradient(#3e86ff, #2a5cae, #2a5cae);
            }
        
            .red {
              background-image: linear-gradient(#ff0606, #af0404, #af0404);
            }
          }
          .form-radio {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: inline-block;
            position: relative;
            // background-color: rgb(92, 98, 115);
            background: url("../../../public/radio-buttons/radio_button.png") left no-repeat;
            color: #ffffff;
            height: 30px;
            width: 30px;
            cursor: pointer;
            outline: none;
          }
          .form-radio:checked::before {
            position: absolute;
            content: url("../../../public/radio-buttons/radio_button-active.png");
            color: #fff;
            font: 12px/2 "Open Sans", sans-serif;
            top: 3px;
          }
          .form-radio:checked {
            background: none;
          }
    }

  


    .radio-button-group{
        display: flex;
        justify-content: space-around;
        flex-direction: column-reverse;
        height: 42px;
        padding-top: 10px;
    }

    .fieldset{
        width: 328px;
        height: 150px;
        padding: 0px 20px 0px 20px;
        border: lightgray solid 1px;
        /* border-width: thin; */
        border-radius: 6px;
    }
    .fieldset2{
        width: 328px;
        height: 230px;
        padding: 0px 20px 0px 20px;
        border: lightgray solid 1px;
        /* border-width: thin; */
        border-radius: 6px;
    }
    .fileChooseDisabled{
        display: none !important;
    }
}
// @media print {
//   .Print{
//     display: none !important;
//   }
// }
@media print {
  .print-dialog{
    display: none !important;
  }
}