.introjs-tooltip {
  background-color: rgba(0, 0, 0, 0.69) !important;
}

.introjs-overlay {
  background: rgba(0, 0, 0, 0.78) !important;
  /* Darken the overall background */
}

.introjs-tooltip-title {
  color: #fffffff1;
}

.introjs-tooltip .introjs-bullets {
  max-width: 100%;
  margin-top: 3px;
  overflow: auto !important;
  margin-left: 4px;
  margin-right: 4px;
}

.introjs-tooltip .introjs-bullets a {
  margin-bottom: 5px;

}

.introjs-bullets ul li a.active {
  width: 6px !important;
  background: #2a83eb !important;
  text-decoration: none !important;
  outline: 0 !important;
  transform: scale(1.5) !important;
}

.introjs-tooltip .introjs-bullets ul {
  margin-right: 5px;
  margin-left: 5px;

}

.introjs-bullets ul li a:hover {
  width: 6px !important;
  background: #2a83eb !important;
  text-decoration: none !important;
  outline: 0 !important;
  transform: scale(1.5) !important;
}

.TooltipCustomClass {
  max-width: none !important;
  margin-left: -375px !important;
  margin-top: -375px !important;
  background-color: rgba(12, 10, 10, 0.74);
  color: #fff;
}

.TooltipCustomClass img {
  opacity: 1 !important;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 600px;
}

.TooltipCustomClassExp img {
  margin-top: 7px;
  width: 250px;
  margin-bottom: -17px;
  opacity: 1 !important;
}

.introjs-tooltiptext {
  font-size: 15px;
  opacity: 1 !important;
  margin-top: -15px;
  margin-bottom: -7px;
  color: #fff;
}

.top-right {
  display: none !important;
}

.TooltipCustomClassPano {
  max-width: none !important;
  background-color: rgba(12, 10, 10, 0.74);
  color: #fff;
  margin-right: 2.8rem;
}

.TooltipCustomClassPano img {
  opacity: 1 !important;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 400px;
  height: auto;
}

.TooltipCustomClassToolbar {
  max-width: none !important;
}

.TooltipCustomClassToolbar img {
  max-width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.TooltipCustomClassToolbar span {
  display: flex;
  justify-content: center;
}

.TooltipCustomClassToolbar .introjs-bullets {
  margin-top: -25px;
}