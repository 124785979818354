@import '../../../index.scss';

.p-dialog{
    .p-dialog-title{
        font-size: 1.1rem !important;
    }
    .line{
        display: flex;
        align-items: center;
        .label{
            min-width: 100px;
            font-weight: 600;
        }
        .p-disabled, .p-component:disabled{
            opacity: 1 !important;
            min-width: 200px !important;
        }
        .p-inputnumber {
            width: 66%;
        }
        .p-colorpicker-overlay {
            /* padding-left: 20px; */
            position: relative;
            margin-left: -30px;
            margin-top: 3px;
        }
    }
    .attachment-line{
        display: flex;
        .label{
            min-width: 100px;
            font-weight: 600;
        }
        .p-disabled, .p-component:disabled{
            opacity: 1 !important;
            min-width: 200px !important;
        }
        .file-group{
            display: flex;
        }
    }
   
    .file-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        align-items: center;
        width: 200px;
        .image-container{
            display:flex;
            flex-direction: column;
            align-items: center;
            .file-name{
                word-break: break-all;
                padding-left: 5px;
                font-size: 12px;
                text-align: center;
            }
            .button-container {
              color: $primaryColor;
              text-decoration: underline;
              font-size: 13px;
              &:hover{
                  cursor: pointer;
              }
            }
            .p-image {
                margin-top: 18px;
                img{
                    width: 200px !important;
                }
            }
        }
        .file-container{
            display:flex;
            flex-direction: column;
            align-items: center;
            .file-name{
                word-break: break-all;
                padding-left: 5px;
                font-size: 12px;
                text-align: center;
            }
            .button-container {
              color: $primaryColor;
              text-decoration: underline;
              font-size: 13px;
              display: flex;
              justify-content: center;
              &:hover{
                  cursor: pointer;
              }
            }
            .p-image {
                margin-top: 18px;
                img{
                    width: 30px !important;
                }
            }
        }
    }

}
